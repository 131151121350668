import React from 'react';
import ItemList from 'components/itemlist'
import AddItemForm from 'components/additem'


const Pricing= () => {
  


  return (
 
      <div>
        <div>
        <button ><a href="/">Logout</a></button>
        <br/>
        <br/>
        </div>
    <h1>Premas Life Sciences - ADMIN PLATFORM </h1>
    <ItemList/>

  <br/>
  <br/>
    <h2>LIST JOBS</h2>
    <AddItemForm/>
    
    
  </div>

   
  );
};

export default Pricing;
